import styled from "styled-components"

export const UnstyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
`

export const Nav = styled.nav`
  display: flex;
  flex: 1 0 auto;
  text-align: right;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  a {
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-size: 0.85rem;
    letter-spacing: 3.5px;
    font-family: "visionlight";
  }
`
